import httpClient  from '.'; 
const API_CONTROLLER = 'project-budget/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    explain(data) { 
        let url = API_CONTROLLER + 'explain';
        return httpClient.post(url, data);
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    submitApprove(data) { 
        let url = API_CONTROLLER + 'submit-approved';
        return httpClient.post(url, data);
    },

    requireEdit(data) { 
        let url = API_CONTROLLER + 'require-edit';
        return httpClient.post(url, data);
    },

    getAll(projectId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                projectId: projectId
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                projectId: search.projectId,
                status: search.status,
                keyword: search.keyword
            }
        });
    },

    getForApprove(id) { 
        let url = API_CONTROLLER + 'get-for-approve';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getForApproves(search) { 
        let url = API_CONTROLLER + 'get-for-approves';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                staffStatus: search.staffStatus,
                code: search.code
            }
        });
    },
}